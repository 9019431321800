/**
 * Copyright © 2024 Adnuntius AS.
 */
import angular from 'angular';
import _ from 'lodash';
import uiCodemirror from 'angular-ui-codemirror';
import {OBJECT_TYPE} from "../common/constants/object-type";
import {MEDIA_TYPE, MediaTypeHelper} from "../../components/util/media-type-helper";

import targetingTemplate from '../targeting/base-targeting-editor-modal.html';

import standardFormModule from '../common/standard-form-module';
import creativePreviewComp from './ad/creative-preview-via-layout-comp';
import mimeTypeFilter from '../common/filters/mimeTypeFilter';
import otherFormButtons from '../common/directives/form-controls/form-buttons';
import externalAdUnitComponent from '../common/directives/external-ad-unit-component';
import assetsDirective from './asset/assets-directive';
import targetingModule from '../targeting/targeting-module';
import creativeHelper, {DEFAULT_LAYOUT_TYPES} from './creative-controller-helper';
import creativeViewComp from './ad/creative-preview-via-creative-id-component';
import {adnFormHelper} from "../common/controller/form-helper";
import {ADN_TARGETING_TYPES} from "../targeting/targeting-constants";
import {LocalBulkCreativeInfo} from "../../components/session/local-bulk-creative-info";

const MODULE_NAME = 'creative-controller';

angular.module(MODULE_NAME, [
  uiCodemirror,
  mimeTypeFilter,
  standardFormModule,
  creativePreviewComp,
  otherFormButtons,
  assetsDirective,
  creativeHelper,
  creativeViewComp,
  externalAdUnitComponent,
  targetingModule
])

  .controller('CreativeCtrl', function($q, readOnly, $state, $stateParams, $timeout, Team, CreativeHelper, creativeType, adnListHelper, Rollbar, MarketplaceProduct, LocalNetworkProfile, $uibModal, LineItem, lineItemInfo, model, modelComms, ExternalDemandSource, Asset, Creative, LibraryCreative, LocalUserPermissions) {
    const ctrl = this,
      mInitPromises = [],
      mLayoutAssetMatches = {},
      defaultLayoutType = 'OTHER';

    let mAllLayoutsPromise;
    let mLineItemPromise;

    ctrl.skips = ['validation.warning.creative.blocked.by.scanner'];
    ctrl.isPureMarketplace = LocalNetworkProfile.isPureMarketplacePlatform();
    ctrl.lineItemParent = lineItemInfo.fromLineItem || _.get(model, ['lineItem', 'id']);
    ctrl.creativeSetParent = lineItemInfo.fromCreativeSet || _.get(model, ['creativeSet', 'id']);
    ctrl.useCookies = true;
    if (ctrl.creativeSetParent && _.get(model, ['creativeSet', 'id'])) {
      lineItemInfo.id = lineItemInfo.id || _.get(model, ['creativeSet', 'id']);
      lineItemInfo.name = lineItemInfo.name || _.get(model, ['creativeSet', 'id']);
    }

    function doBulkUpdates(localCreatives) {
      if (ctrl.isBulkCreative) {
        return;
      }
      ctrl.bulkCreatives = localCreatives;
      ctrl.isBulkCreative = _.find(ctrl.bulkCreatives, function(c) {
        return c.id === model.id;
      });
    }
    doBulkUpdates(LocalBulkCreativeInfo.obtain().getCreatives());
    doBulkUpdates(LocalBulkCreativeInfo.obtain().getLibraryCreatives());
    doBulkUpdates(LocalBulkCreativeInfo.obtain().getVideoCreatives());

    ctrl.defaultLayoutTypes = DEFAULT_LAYOUT_TYPES;
    ctrl.layoutTypes = ctrl.defaultLayoutTypes.concat(defaultLayoutType);
    ctrl.isNew = $stateParams.isNew;

    ctrl.isSelfService = LocalUserPermissions.isSelfService();
    ctrl.isAxAdvertiser = LocalUserPermissions.isAxAdvertiser() && !(ctrl.creativeSetParent || ctrl.fromCreativeSet);
    ctrl.needsVerificationCheck = LocalNetworkProfile.isVerificationCheckNetwork();
    ctrl.isCreative = creativeType.type === 'Creative';
    ctrl.perms = LocalUserPermissions.getAllVisPermissions();
    ctrl.creativeFields = ['name', {param: 'Layout', label: 'layout'}, {param: 'LineItem', label: 'lineItem'}, {label: 'dimensions', param: 'width'}, {param: '', label: 'info'}, 'updateTime', {param: '', label: 'targeting'}];
    ctrl.linkedCreativeFields = ['name', {param: 'LineItem', label: 'lineItem'}, {label: 'dimensions', param: 'width'}, {param: '', label: 'info'}, 'updateTime', {param: '', label: 'targeting'}];

    let teamQuery;
    if (!ctrl.isCreative) {
      const params = {minimal: true};
      if (ctrl.isPureMarketplace) {
        params.filterBy = 'type';
        params.filterByValue = 'AX_ADVERTISER';
      }
      teamQuery = Team.query(params).$promise.then(function(page) {
        ctrl.allTeams = page.results;
      });
    }

    const prevAssetAssignments = {};
    let marketplaceProduct = {};

    let timeoutPromise;
    const delayedPreview = function() {
      $timeout.cancel(timeoutPromise);
      timeoutPromise = $timeout(function() {
        callForPreview();
      }, 1500);
    };

    if (!ctrl.isCreative && !ctrl.isNew) {
      adnListHelper.setUpBasicList(ctrl, Creative, 'fullCreativesforLibraryCreative', {
        moreParams: {libraryCreative: model.id},
        afterFill: function() {
          ctrl.relCreativesEmpty = _.isEmpty(ctrl.items);
          ctrl.relCreatives = ctrl.items;
          ctrl.relCreativesCount = Object.keys(ctrl.items).length;
        }
      });
    }

    const updateDefaultName = function() {
      CreativeHelper.updateDefaultName(ctrl);
    };

    const updateCreativeDims = function() {
      CreativeHelper.updateCreativeDims(ctrl, prevAssetAssignments);
    };

    function getLineItemInfo() {
      mLineItemPromise = LineItem.get({id: ctrl.model.lineItem.id}, function(lineItem) {
        ctrl.lineItem = lineItem;
        ctrl.lineItemInfo = lineItem;
        if (ctrl.isSelfService) {
          ctrl.isLockedDown = lineItem.userState !== 'PROPOSED';
        }
        if (_.get(ctrl.lineItem, ['axProduct', 'id'])) {
          MarketplaceProduct.get({id: lineItem.axProduct.id}, function(mp) {
            marketplaceProduct = mp;
            ctrl.optionalTargetTypes = _.isArray(mp.optionalTargetTypes) ? mp.optionalTargetTypes : [];
            $q.when(mAllLayoutsPromise).then(function() {
              ctrl.layouts = _.filter(ctrl.layouts, function(lay) {
                return _.get(ctrl.model, ['layout', 'id']) === lay.id || _.find(mp.layouts, function(l) {
                  return l.id === lay.id;
                });
              });
            });
            ctrl.hasAxProductDimensions = marketplaceProduct && Array.isArray(marketplaceProduct.allowedDimensions) && marketplaceProduct.allowedDimensions.length > 0;
            ctrl.getAllowedDimensions = marketplaceProduct.allowedDimensions.map(allowedDimension => {
              return {
                label: allowedDimension[0] + " x " + allowedDimension[1],
                width: allowedDimension[0],
                height: allowedDimension[1]
              };
            });
            ctrl.selectedDimension = ctrl.getAllowedDimensions.find(dimension => ctrl.model.width === dimension.width && ctrl.model.height === dimension.height);
            if (ctrl.hasAxProductDimensions) {
              ctrl.model.dimensionType = "FIXED";
            }
          });
        }
      }).$promise;
    }

    let checkHtmlUrls = function() {
        CreativeHelper.checkHtmlUrls(ctrl);
      },
      matchConstraintsAndAssets = function(newAssetUpload) {
        return CreativeHelper.matchConstraintsAndAssets(ctrl, mInitPromises, mLayoutAssetMatches, prevAssetAssignments, delayedPreview, newAssetUpload);
      },
      updateEmailCode = function() {
        ctrl.emailCodeReason = null;
        const destUrl = _.find(ctrl.model.cToUrls, function(v) {
          return !!v;
        }) || "";

        if (!destUrl) {
          ctrl.emailCodeReason = "A creative tag for emails requires a destination URL to be entered.";
          return;
        }

        const assets = _.map(ctrl.model.cToAssets, function(a, key) {
          return {
            key: key,
            asset: a
          };
        });
        const imageIds = _.map(_.filter(ctrl.acsWithAssetMatches, function(ac) {
          return MediaTypeHelper.getMediaType(ac.mimeTypes).image;
        }), function(ac) {
          return ac.tag;
        });
        const hasImage = !!_.find(assets, function(asset) {
          return imageIds.indexOf(asset.key) > -1 && _.get(asset, ['asset', 'id']);
        });

        if (!hasImage) {
          ctrl.emailCodeReason = "A creative tag for emails requires at least one image to be part of your creative.";
        }
      },
      callForPreview = function() {
        $q.when(mAllLayoutsPromise).then(function() {
          updateEmailCode();

          let previewResults = function(failureReason, previewData) {
            ctrl.selPreviewFail = failureReason;
            ctrl.selPreview = previewData;

            const selLayoutType = _.get(ctrl.model, ['layout', 'type'], "unspecified");
            const key = ctrl.defaultLayoutTypes.indexOf(selLayoutType) > -1 && selLayoutType !== 'HTML' ? "style1" : "style2";
            if (previewData && ctrl.selPreviewTrigger[key].previewCreative) {
              ctrl.selPreviewTrigger[key].previewCreative(previewData);
            }
          };
          if (!ctrl.model.layout || !ctrl.model.layout.id) {
            previewResults("A valid layout needs to be selected before a creative can be previewed.");
            return;
          }

          const adServingWarnings = _.find(ctrl.model.layout.validationWarnings, function(v) {
            return v.preventsAdServing;
          });
          if (adServingWarnings) {
            previewResults("The select layout is invalid and cannot be used to preview a creative.");
            return;
          }


          let creativeAssets = ctrl.model.assets.concat(ctrl.model.libraryCreativeAssets || []);
          let availableAssets = _.map(creativeAssets, function(asset) {
            return _.omit(asset, ['matchingAcs', 'assetComponents']);
          });
          if (availableAssets.length === 0 && _.filter(ctrl.model.layout.assetComponents, ['required', 'MANDATORY']).length > 0) {
            previewResults("Mandatory assets must be made available before a creative can be previewed.");
            return;
          }

          let assets = _.filter(availableAssets, function(asset) {
            let assetIds = _.keyBy(_.valuesIn(ctrl.model.cToAssets), 'id');
            return assetIds[asset.id];
          });
          let text = _.filter(ctrl.model.cToText, function(v) {
            return !!v;
          });
          let links = _.filter(ctrl.model.cToUrls, function(v) {
            return !!v;
          });
          let choices = _.filter(ctrl.model.cToChoices, function(v) {
            return !!v;
          });

          const mandatoryCompLength = _.filter(_.get(ctrl.model, ['layout', 'layoutComponents'], []), function(lc) {
            return lc.required === 'MANDATORY';
          }).length;
          if (mandatoryCompLength > 0 && assets.length === 0 && text.length === 0 && links.length === 0 && choices.length === 0) {
            previewResults("At least one layout component needs an asset or text assigned to it before a creative can be previewed.");
            return;
          }

          if (ctrl.model.dimensionType === 'FIXED' && (!ctrl.model.width || !ctrl.model.height)) {
            previewResults("The creative must have a width and height before it can be previewed.");
            return;
          }
          if (ctrl.model.dimensionType === 'FIXED_HEIGHT' && !ctrl.model.height) {
            previewResults("The creative must have a height before it can be previewed.");
            return;
          }
          if (ctrl.model.dimensionType === 'FIXED_WIDTH' && !ctrl.model.width) {
            previewResults("The creative must have a width before it can be previewed.");
            return;
          }

          const creativeModel = _.cloneDeep(ctrl.model);
          CreativeHelper.doLayoutParams(ctrl, creativeModel);
          previewResults(null, {
            layoutId: ctrl.model.layout.id,
            creative: creativeModel,
            // for now the preview api in the ad server expects the video param even for vast audio
            video: _.get(ctrl.model, ['layout', 'ogLayoutType']) === 'VAST'
          });
        });
      };


    function saveOverridableTargeting() {
      ctrl.model.overridableTargeting = [];
      _.forEach(ctrl.tOverrides, function(val, key) {
        if (val) {
          ctrl.model.overridableTargeting.push(key);
        }
      });
    }

    function overridableTargetingForCtrl() {
      ctrl.tOverrides = {};
      ctrl.tOverridesTypes = [];
      _.forEach(ADN_TARGETING_TYPES, function(val) {
        const ot = _.find(ctrl.model.overridableTargeting, function(tt) {
          return tt === val.apiType;
        });
        if (ot) {
          ctrl.tOverridesTypes.push(val.id);
        }
        ctrl.tOverrides[val.apiType] = !!ot;
      });
    }

    function checkShowGeneralVariables() {
      ctrl.showGeneralVariables = _.get(ctrl, ['model', 'layout', 'layoutType']) === 'THIRD_PARTY' || _.find(ctrl.allComponents || [], function(c) {
        return c.type === 'TEXT' && c.textType === 'CODE';
      });
    }

    const afterInitAfterSave = function() {
        ctrl.hasLibraryCreative = !!_.get(ctrl.model.libraryCreative, ['id'], '');
        ctrl.lcOverrides = {};
        _.forEach(ctrl.model.overridableLayoutParameters, function(key) {
          ctrl.lcOverrides[key] = true;
        });
        overridableTargetingForCtrl();
        ctrl.dimensionOverrides = _.indexOf(ctrl.model.overrideFields, 'width') > -1 || _.indexOf(ctrl.model.overrideFields, 'height') > -1 || _.indexOf(ctrl.model.overrideFields, 'dimensionType') > -1;
        ctrl.savedTeams = _.clone(ctrl.model.teams);
        ctrl.model.renderOption = ctrl.model.renderOption || null;

        ctrl.advertiserMismatch = _.find(ctrl.model.validationWarnings, function(vw) {
          return vw.code === "validation.warning.creative.advertiser.mismatch.order.advertiser";
        });

        $q.when(CreativeHelper.deriveFee(mLineItemPromise, ctrl, ctrl.model.creativeFormatFee).then(function(fee) {
          ctrl.model.simplifiedCreativeFormatFee = fee;
        }));

        if (!ctrl.isCreative && ctrl.isPureMarketplace) {
          $q.when(teamQuery).then(function() {
            ctrl.model.singularTeam = ctrl.model.teams[0];
            if (!ctrl.model.singularTeam && ctrl.allTeams.length === 1) {
              ctrl.model.singularTeam = _.get(ctrl, ['allTeams', 0, 'id']);
            }
          });
        }
        checkShowGeneralVariables();
      },
      afterInit = function() {
        ctrl.model = creativeType.caps === 'CREATIVE' ? new Creative(ctrl.model) : new LibraryCreative(ctrl.model);
        ctrl.impTrackingUrls = _.map(ctrl.model.impressionTrackingUrls, function(url) {
          return {trackingUrl: url};
        });

        // for an AX_PUBLISHER viewing a creative, some parts of the creative will be suppressed
        const axPublisherPermission = ctrl.model.permission === 'AX_PUBLISHER';
        ctrl.readOnlyAxPublisher = axPublisherPermission && !ctrl.model.targeting;
        ctrl.showTargeting = !axPublisherPermission || ctrl.model.targeting;
        ctrl.showExternalAdUnits = !axPublisherPermission || ctrl.model.externalAdUnits;
        ctrl.showCpm = !axPublisherPermission || ctrl.model.cpm;
        ctrl.blockSubmit = readOnly || ctrl.blockSubmit || ctrl.readOnlyAxPublisher;

        ctrl.titleKey = model ? 'creative.edit' : 'creative.add';
        ctrl.model.targeting = ctrl.model.targeting || {};
        ctrl.model.cToAssets = ctrl.model.cToAssets || {};
        ctrl.model.userState = ctrl.model.userState || 'APPROVED';
        ctrl.model.type = ctrl.model.type || 'INTERNAL';
        ctrl.htmlAcWithAssetMatches = {};
        ctrl.selPreviewTrigger = {
          style1: {},
          style2: {}
        };
        ctrl.model.teams = _.map(ctrl.model.teams, 'id');

        ctrl.isLockedDown = false;
        ctrl.showStats = !axPublisherPermission;

        ctrl.model.dimensionType = ctrl.model.dimensionType || 'FIXED';

        if (!_.get(lineItemInfo, ['id']) && !_.get(ctrl.model, ['lineItem', 'id']) && !_.get(ctrl.model, ['creativeSet', 'id'])) {
          ctrl.orphan = true;
        }

        if ($state.previousParams.advertiser) {
          ctrl.model.advertiser = $state.previousParams.advertiser;
          ctrl.advertiserSpecified = true;
        }
        if (ctrl.model.advertiser && ctrl.orphan) {
          ctrl.advertiserSpecified = true;
        }

        if (_.get(ctrl.model, ['lineItem', 'id'])) {
          getLineItemInfo();
        }

        if (!mAllLayoutsPromise) {
          mAllLayoutsPromise = CreativeHelper.getLayouts(ctrl, defaultLayoutType, mLineItemPromise);
        }
        mInitPromises.push(mAllLayoutsPromise);
        if (ctrl.model.id) {
          ctrl.models = [ctrl.model];

          $q.when(mAllLayoutsPromise).then(function() {
            if (ctrl.model.layout) {
              let hydratedLayout = _.find(ctrl.layouts, ['id', ctrl.model.layout.id]);
              if (hydratedLayout) {
                ctrl.model.layout = hydratedLayout;
              }
            }
          });
        }
        $q.all(mInitPromises).then(function() {
          $q.when(matchConstraintsAndAssets()).then(function() {
            delayedPreview();
            checkShowGeneralVariables();
          });
        });

        if (ctrl.initExtAdUnits) {
          ctrl.initExtAdUnits();
        }
        afterInitAfterSave();
      };

    let afterSave = function(creative) {
      ctrl.targetingResult = {data: false};
      ctrl.isNew = false;
      ctrl.model.targeting = creative.targeting || {};
      ctrl.hasLibraryCreative = !!_.get(creative.libraryCreative, ['id'], '');

      ctrl.teamsWarning = (_.get(ctrl.model, 'teams') || []).length !== (_.get(creative, 'teams') || []).length;
      _.set(ctrl.model, 'teams', _.map(_.get(creative, 'teams') || [], 'id'));

      if (_.isFunction(ctrl.eventHook.onCreativeSave)) {
        ctrl.eventHook.onCreativeSave();
      }
      if (ctrl.toLineItem && _.get(creative, ['lineItem', 'id'])) {
        $state.go('app.line-items.line-item', {id: creative.lineItem.id});
        return;
      }
      if (ctrl.toCreativeSet && _.get(creative, ['creativeSet', 'id'])) {
        $state.go('app.creative-sets.creative-set', {id: creative.creativeSet.id});
        return;
      }
      if (_.get(ctrl.model, ['lineItem', 'id']) && ctrl.orphan) {
        getLineItemInfo();
        ctrl.postOrphan = true;
      }
      if (ctrl.initExtAdUnits) {
        ctrl.initExtAdUnits();
      }
      afterInitAfterSave();
      delayedPreview();
    };

    ctrl.cancelShareOfVoice = function() {
      ctrl.model.sponsorshipPercentage = undefined;
      ctrl.editsMade();
    };

    adnFormHelper.setUpForm(ctrl, model, {afterInit: afterInit, afterSave: afterSave, modelComms: modelComms});
    ctrl.toLineItem = !!lineItemInfo.fromLineItem;
    ctrl.toCreativeSet = !!lineItemInfo.fromCreativeSet;
    ctrl.lineItemInfo = lineItemInfo;

    ctrl.breakLink = function() {
      const libCreative = _.cloneDeep(ctrl.model);
      libCreative.libraryCreative = null;
      ctrl.processingForm();
      ctrl.isDelinking = true;
      libCreative.savePartial("libraryCreative").then(function(creative) {
        ctrl.model.libraryCreative = creative.libraryCreative;
        ctrl.saveDirect(creative);
      }).finally(function() {
        ctrl.successfulSave();
        ctrl.isDelinking = false;
      });
    };

    ctrl.assetAssignmentChanged = function(tag) {
      let selectedAsset = _.get(ctrl.model, 'cToAssets.' + tag);
      if (selectedAsset && MediaTypeHelper.isMediaType(selectedAsset.mimeType, MEDIA_TYPE.html) && ctrl.primaryHtmlAsset) {
        ctrl.primaryHtmlAsset = selectedAsset;
        checkHtmlUrls();
      }
      updateCreativeDims();
      delayedPreview();
      updateDefaultName();
      if (_.isFunction(ctrl.eventHook.onAssetUpdate)) {
        ctrl.eventHook.onAssetUpdate();
      }
    };

    ctrl.selectTeams = function() {
      ctrl.editsMade();
      ctrl.model.teams = _.map(ctrl.allTeams, 'id');
    };

    ctrl.deselectTeams = function() {
      ctrl.editsMade();
      ctrl.model.teams = [];
    };

    ctrl.selectLayout = function(layout) {
      if (layout) {
        ctrl.model.layout = layout;
      }
      ctrl.model.cToAssets = {};

      if (ctrl.model.defaultLayoutWidth === ctrl.model.width && ctrl.model.defaultLayoutHeight === ctrl.model.height) {
        ctrl.model.width = ctrl.model.layout.defaultWidth;
        ctrl.model.height = ctrl.model.layout.defaultHeight;
      }
      ctrl.model.width = ctrl.model.width || ctrl.model.layout.defaultWidth;
      ctrl.model.height = ctrl.model.height || ctrl.model.layout.defaultHeight;
      ctrl.model.defaultLayoutWidth = ctrl.model.layout.defaultWidth;
      ctrl.model.defaultLayoutHeight = ctrl.model.layout.defaultHeight;
      $q.when(matchConstraintsAndAssets(true)).then(function() {
        checkShowGeneralVariables();
        callForPreview();
      });
    };

    ctrl.addImpressionTrackingUrl = function() {
      ctrl.impTrackingUrls = ctrl.impTrackingUrls || [];
      ctrl.impTrackingUrls.push({trackingUrl: null});
    };

    ctrl.eventHook = {
      onUpdate: function() {
        ctrl.editsMade();
        matchConstraintsAndAssets(true);
      },
      assignAsset: function(asset, tag) {
        ctrl.editsMade();
        ctrl.model.cToAssets[tag] = asset;
        delayedPreview();
      }
    };

    ctrl.componentValueUpdated = function() {
      delayedPreview();
    };

    ctrl.dimensionsChange = function() {
      delayedPreview();
    };

    ctrl.editTarget = function() {
      ctrl.targetingResult = {data: false};
      $uibModal.open({
        template: targetingTemplate,
        controller: 'TargetingCtrl',
        size: 'xl',
        resolve: {
          modelLineItem: function() {
            const lineItemCopy = angular.copy(ctrl.lineItem) || {};
            lineItemCopy.creativesForReach = [ctrl.model];
            lineItemCopy.fromCreative = true;
            return lineItemCopy;
          },
          pageType: function() {
            return "creative";
          },
          reachEnabled: function() {
            return 'CREATIVE';
          },
          targetingMode: function() {
            return null;
          },
          overridableTargeting: function() {
            return ctrl.hasLibraryCreative ? ctrl.tOverrides : null;
          },
          mandatoryTargets: _.noop,
          availableTargets: function() {
            if (!_.isArray(ctrl.optionalTargetTypes) || !marketplaceProduct) {
              return null;
            }
            const targetIds = _.map(_.filter(ADN_TARGETING_TYPES, function(tt) {
              return ctrl.optionalTargetTypes.indexOf(tt.apiType) > -1;
            }), 'id');
            return _.pick(ADN_TARGETING_TYPES, targetIds);
          },
          targeting: function() {
            return angular.copy(ctrl.model.targeting);
          },
          isLockedDown: function() {
            return ctrl.isLockedDown;
          }
        }
      })
        .result.then(function(wc) {
        const fieldsForSaving = ['targeting'];
          if (wc.overrides) {
            ctrl.tOverrides = wc.overrides;
            saveOverridableTargeting();
            fieldsForSaving.push("overridableTargeting");
          }
        _.assign(ctrl.model.targeting, wc.wc);
        _.assign(ctrl.model.overridableTargeting, wc.overrides);
        if (!ctrl.isNew) {
          overridableTargetingForCtrl();
          ctrl.model.savePartial(fieldsForSaving).then(function(respCreative) {
            ctrl.targetingResult.data = true;
            _.set(ctrl.model, 'validationWarnings', respCreative.validationWarnings);
            _.set(ctrl.model, 'targeting', respCreative.targeting);
          }, function(err) {
            ctrl.targetingResult.errors = _.get(err, ['data', 'errors']);
            Rollbar.error("Creative targeting problem", err);
          });
        }
      }, function() {
        // dismissal handler to avoid unhandled exceptions -- don't blame me, blame uibModal
      });
    };

    ExternalDemandSource.query().$promise.then(function(connPage) {
      ctrl.extDemandSources = connPage.results;
    });

    ctrl.refreshCodeMirrorVar = false;
    ctrl.refreshCodeMirror = function() {
      $timeout(function() {
        ctrl.refreshCodeMirrorVar = true;
      }, 100);
    };

    modelComms.addSubscriber(function(data, type) {
      if (data && type === OBJECT_TYPE.LineItem) {
        ctrl.lineItemInfo = data;
      }
    });

    ctrl.onBeforeSubmit = function() {
      if (ctrl.unreject && ctrl.model.userState === 'REJECTED') {
        ctrl.model.userState = 'APPROVED';
      }
      if (!_.isFinite(ctrl.model.sponsorshipPercentage)) {
        ctrl.model.sponsorshipPercentage = null;
      }
      // remove any empty-string impression-tracking urls.
      ctrl.model.impressionTrackingUrls = _.map(_.filter(ctrl.impTrackingUrls, function(url) {
        return url.trackingUrl && url.trackingUrl.length > 0;
      }), 'trackingUrl');

      _.forEach(_.keysIn(ctrl.model.cToAssets), function(t) {
        if (!ctrl.model.cToAssets[t]) {
          delete ctrl.model.cToAssets[t];
        }
      });

      ctrl.model.overridableLayoutParameters = [];
      _.forEach(ctrl.lcOverrides, function(val, key) {
        if (val) {
          ctrl.model.overridableLayoutParameters.push(key);
        }
      });
      saveOverridableTargeting();
      ctrl.model.overrideFields = [];
      if (ctrl.dimensionOverrides) {
        ctrl.model.overrideFields.push('width', 'height', 'dimensionType');
      }

      if (ctrl.model.dimensionType === 'FLEXIBLE') {
        ctrl.model.width = null;
        ctrl.model.height = null;
      } else if (ctrl.model.dimensionType === 'FIXED_WIDTH') {
        ctrl.model.height = null;
      } else if (ctrl.model.dimensionType === 'FIXED_HEIGHT') {
        ctrl.model.width = null;
      }

      CreativeHelper.doLayoutParams(ctrl);
      if (_.isEmpty(ctrl.model.targeting)) {
        // this is to avoid an API bug
        delete ctrl.model.targeting;
      }
      if (ctrl.primaryHtmlAsset) {
        Asset.update(ctrl.model.id, {htmlUrls: ctrl.primaryHtmlAsset.htmlUrls}, ctrl.primaryHtmlAsset.id);
      }

      if ((_.get(ctrl.model, ['cpm', 'amount']) || 0) <= 0) {
        // This will delete the CPM on the server
        ctrl.model.cpm = null;
      }

      if (ctrl.isPureMarketplace && !ctrl.isCreative) {
        ctrl.model.teams = ctrl.model.singularTeam ? [ctrl.model.singularTeam] : null;
      }

      if (ctrl.beforeSubmitExtAdUnit) {
        ctrl.beforeSubmitExtAdUnit();
      }
    };

    ctrl.selectDimension = function() {
      if (ctrl.selectedDimension) {
        ctrl.model.width = ctrl.selectedDimension.width;
        ctrl.model.height = ctrl.selectedDimension.height;
        delayedPreview();
      }
    };
  })

  .filter('assetFilter', function() {
    return function(assets) {
      return _.filter(assets, function(a) {
        return a.objectState === 'ACTIVE' && (!a.htmlCreativeType || MediaTypeHelper.isMediaType(a.mimeType, MEDIA_TYPE.html));
      });
    };
  });

export default MODULE_NAME;
